<template>
  <v-container>
    <v-row class="app-page">
      <v-col>
        <v-card
          v-for="record in companies"
          class="list-item"
          :key="record.CodigoEmp"
          @click="openLogin(record)"
        >
          <div class="pua" :class="{ 'active': record.CodigoEmp == user.company_cod }">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="35"
              height="33"
              viewBox="0 0 35 33"
              fill="none"
            >
              <path
                d="M8.31273 0.126751C1.97281 1.49578 -0.104376 8.87434 0.00399863 16.484C0.00399896 24.0937 1.97281 31.4723 8.31273 32.8413C8.9991 32.948 10.0467 33.0724 10.8595 32.948C13.4063 32.7169 16.1699 31.4723 18.2471 30.6722C23.7922 28.2897 28.633 24.876 32.6609 20.4489C33.4737 19.5421 34.6297 18.5109 34.9729 17.0352C34.9729 16.9285 35.0813 16.3596 34.8645 15.6662C34.3949 14.4216 33.4737 13.3904 32.679 12.6081C28.633 8.18093 23.7922 4.76724 18.2651 2.38476C16.188 1.478 13.4244 0.340106 10.8776 0.10897C10.0467 -0.086606 8.9991 0.0200726 8.31273 0.126751Z"
                fill="#DADEE5"
                style="&#10;"
              />
            </svg>
          </div>
          <div class="list-content">
            <div class="nombre">{{ record.Nombre }}</div>
            <div class="categoria">{{ record.rut }}</div>
          </div>
        </v-card>
      </v-col>
    </v-row>
    <v-row v-if="1==0">
      <v-col class="text-right">
        <router-link :to="{ name: 'empresa_nueva' }">
          <v-btn class="fab-btn" dark large bottom right fab fixed>
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </router-link>
      </v-col>
    </v-row>

    <v-dialog v-model="dialog" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="headline">Inicio de sesión</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  prepend-icon="mdi-account"
                  label="Ingresa tu usuario"
                  v-model="login.username"
                  :rules="login.usernameRules"
                  name="username"
                  required
                  v-on:keyup.enter="processLogin()"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="login.password"
                  prepend-icon="mdi-lock"
                  :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  :rules="login.passwordRules"
                  :type="showPassword ? 'text' : 'password'"
                  name="password"
                  label="Ingresa tu password"
                  counter
                  @click:append="showPassword = !showPassword"
                  v-on:keyup.enter="processLogin()"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions class="pt-0 pr-6 pb-8">
          <v-spacer></v-spacer>
          <v-btn color="secondary" text @click="dialog = false">Cancelar</v-btn>
          <v-btn color="primary" text @click="processLogin()">Ingresar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "empresas-list",
  data() {
    return {
      loading: false,

      showreset: false,
      redirect: undefined,
      showPassword: false,
      valid: false,

      login: {
        username: "",
        usernameRules: [(v) => !!v || "El usuario es requerido"],

        password: "",
        passwordRules: [(v) => !!v || "El password es requerido"],
      },

      reset: {
        username: "",
        usernameRules: [(v) => !!v || "El usuario es requerido"],
      },

      currentCompany: null,
      dialog: false,
    };
  },

  created() {},

  watch: {},

  computed: {
    ...mapGetters(["companies", "user"]),
  },

  mounted() {
    this.$store.commit("setNavigationDrawer", false);
    this.$store.commit("setBackButton", false);
    this.$store.commit("setToolbarTitle", "Empresas");
    this.$store.commit("stopProcessing");
  },

  filters: {},

  methods: {
    openLogin(company) {
      if (company.CodigoEmp != this.user.company_cod) {
        this.currentCompany = company;
        this.dialog = true;
      } else {
        // Mostrar un mensaje
        this.$store.commit("setSnackBar", {
          message: "Ya te encuentras logueado en esta empresa",
          show: true,
        });
      }
    },

    processLogin() {
      let credentials = {
        username: this.login.username,
        empresa: this.currentCompany.CodigoEmp,
        password: this.login.password,
        host: this.currentCompany.mwURLdeSitio,
      };

      this.$store
        .dispatch("user/login", {
          url: process.env.VUE_APP_MIX_BASE_API,
          userInfo: credentials,
        })
        .then(() => {
          this.$store.dispatch("dict/initCollections")
          this.$router.push({ path: this.redirect || "/" });
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
          this.valid = false;

          // Mostrar un mensaje
          this.$store.commit("setSnackBar", {
            message: "Usuario y/o password incorrecto",
            show: true,
          });
        });
    },
  },
};
</script>
